import React from "react";
import Layout from "../layouts/en";
import MapWithAnOverlayView from "../components/LocationMap.js";
import { Container, Row, Col } from "reactstrap";
import SEO from "../components/seo.js";

const features = [
  { number: "12 km", title: "do centra Košíc" },
  { number: "18 km", title: "do centra Prešova" },
  { number: "98 km", title: "k poľským hraniciam" },
  { number: "97 km", title: "k ukrajinským hraniciam" },
  { number: "38 km", title: "k maďarským hraniciam" },
  { number: "21 km", title: "na košické letisko" }
];

const SecondPage = props => (
  <Layout location={props.location}>
    <div>
      <MapWithAnOverlayView
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBOh1wAZLx_f2riCJadaLxEpjUqkGX_Gy8&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `600px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
      <Container fluid className="locations">
      <Container fluid>
         <Row className="">
          <Col md={10} className="features container-basic pt-5 pb-5 f-table-cell">
            <Row>
              {features.map((feature, index) => 
              <Col key={index} className="text-center" xl={4} lg={6} sm={12}>
                <div className="feature-component">
                  <div className="feature-number">
                    <p dangerouslySetInnerHTML={{ __html: feature.number }}></p>
                  </div>
                  <div className="feature-title">
                    <h5 dangerouslySetInnerHTML={{ __html: feature.title }}></h5>
                  </div>
                </div>
              </Col>)}
            </Row>
          </Col>
        </Row>
      </Container>
      <Container fluid className="container-places locations pb-0">
          <Row>
            <h2>Dostupnosť</h2>
            <p>
              Park má skvelú dostupnosť, keďže sa nachádza pri výjazde z diaľnice 
              D1 medzi Košicami a Prešovom. Tieto mestá sú ľahko dostupné autom alebo 
              aj verejnou dopravou. Z letiska Košice to trvá len 30 minút. 
            </p>
          </Row>
        </Container>
      </Container>
      <Container fluid className="locations">
        <Container fluid className="container-places locations pt-0">
          <Row>
            <Col md={6} className="col-utilization">
              <div className="descrtipton-content container-basic div-height justify">
                <h2>Popis</h2>
                <p>
                Logisticko-priemyselný park Nová Polhora sa skladá z jednotlivých 
                areálov S2 až S7. Park leží na hlavnej sídelnej osi Košice-Prešov. 
                Hlavný vstup do parku je z diaľnice D1 cez diaľničný privádzač Nová 
                Polhora, ktorý sa napája na cestu 3325. 
                </p>
                <h2>Moderné stavby</h2>
                <p>
                Stavby v parku sú vhodné na viaceré činnosti ako napríklad logistiku, 
                veľkoobchodný predaj, internetový predaj, maloobchod alebo ľahký priemysel. 
                V rámci stavieb sú k dispozícii aj moderné kancelárie.

                </p>
              </div>
            </Col>
            <Col md={6} className="col-utilization">
              <div className="container-basic div-height blue flex">
                <div className="list-places">
                  <ul>
                    <li> možnosť preskladnenia tovaru</li>
                    <li>nepretržitá bezpečnostná služba, oplotenie</li>
                    <li>flexibilné podmienky nájmu </li>
                    <li>flexibilné rozloženie kancelárii</li>
                    <li>príležitosť rozvoja</li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
    <SEO title="Lokalita" />
  </Layout>
);

export default SecondPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
